// -----------------------------
// CB Widget grid generation Mixins
// -----------------------------

@use "sass:math";

@import "../mixins/breakpoint.scss";
@import "../mixins/grid-framework";
@import "../mixins/grid";

@mixin cbw-make-grid-columns(
  $columns: $grid-columns,
  $gutter: $grid-gutter-width,
  $grid-breakpoints: $grid-breakpoints
) {

  @each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    // Allow columns to stretch full width below their breakpoints
    @for $i from 1 through $columns {
      .cbw-col#{$infix}-#{$i} {
        // @extend %grid-column;
        @include grid-column-mixin($gutter);
      }
    }
    .cbw-col#{$infix},
    .cbw-col#{$infix}-auto {
      // @extend %grid-column;
      @include grid-column-mixin($gutter);
    }

    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      // Provide basic `.col-{bp}` classes for equal-width flexbox columns
      .cbw-col#{$infix} {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }
      .cbw-col#{$infix}-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%; // Reset earlier grid tiers
      }

      @for $i from 1 through $columns {
        .cbw-col#{$infix}-#{$i} {
          @include make-col($i, $columns);
        }
      }

      .cbw-order#{$infix}-first {
        order: -1;
      }

      .cbw-order#{$infix}-last {
        order: $columns + 1;
      }

      @for $i from 0 through $columns {
        .cbw-order#{$infix}-#{$i} {
          order: $i;
        }
      }

      // `$columns - 1` because offsetting by the width of an entire row isn't possible
      @for $i from 0 through ($columns - 1) {
        @if not($infix == "" and $i == 0) {
          // Avoid emitting useless .offset-0
          .cbw-offset#{$infix}-#{$i} {
            @include make-col-offset($i, $columns);
          }
        }
      }
    }

    @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
      .cbw-order#{$infix}-first-only {
        order: -1;
      }

      .cbw-order#{$infix}-last-only {
        order: $columns + 1;
      }

      @for $i from 0 through $columns {
        .cbw-order#{$infix}-#{$i}-only {
          order: $i;
        }
      }
    }
  }
}


@function icon($character) {
  @return unquote('"') + unquote(str-insert($character, "\\", 1)) + unquote('"');
}



@mixin cbw-icon($before: null) {
  &::before {
    content: icon($before);
  }
}