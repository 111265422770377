// -----------------------------
// CB Fonts Mixins
// -----------------------------

// String Replace
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

// Font Face
@mixin font-face($name, $path, $weight: null, $style: null, $exts: woff2 woff ttf ) {
  $src: null;

  $path: $fontPath + $path;

  $extmods: (
    svg: "#" + str-replace($name, " ", "_"),
  );

  $formats: (
    otf: "opentype",
    ttf: "truetype",
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
    font-display: fallback;
  }
}

// Font Face 2
@mixin font-face2($name, $font, $weight: null, $style: null, $exts: svg ttf woff woff2) {
  $src: null;

  $path: $fontPath + $font;

  $extmods: (
    svg: "#" + str-replace($font, " ", "_"),
  );

  $formats: (
    otf: "opentype",
    ttf: "truetype",
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    src: $src;
    font-style: $style;
    font-weight: $weight;
  }
}

// Font Face 2
@mixin font-face-light($name, $font, $weight: null, $style: null, $exts: woff2 woff ttf) {
  $src: null;

  $path: $fontPath + $font;

  $extmods: (
    svg: "#" + str-replace($font, " ", "_"),
  );

  $formats: (
    otf: "opentype",
    ttf: "truetype",
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    src: $src;
    font-style: $style;
    font-weight: $weight;
    font-display: swap;
  }
}

// Better font-rendering on OSX
@mixin font-smoothing() {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin family($name: Roboto) {
  font-family: $name !important;
}

/// ------------------------------------ ROBOTO
@mixin roboto-thin {
  @include family(Roboto);
  font-weight: 100;
}
@mixin roboto-light {
  @include family(Roboto);
  font-weight: 300;
}
@mixin roboto {
  @include family(Roboto);
  font-weight: 400;
}
@mixin roboto-medium {
  @include family(Roboto);
  font-weight: 500;
}
@mixin roboto-bold {
  @include family(Roboto);
  font-weight: 700;
}
@mixin roboto-black {
  @include family(Roboto);
  font-weight: 900;
}

/// ------------------------------------ ROBOTO ITALIC
@mixin roboto-italic-thin {
  @include family(Roboto);
  font-weight: 100;
  font-style: italic;
}
@mixin roboto-italic-light {
  @include family(Roboto);
  font-weight: 300;
  font-style: italic;
}
@mixin roboto-italic {
  @include family(Roboto);
  font-weight: 400;
  font-style: italic;
}
@mixin roboto-italic-medium {
  @include family(Roboto);
  font-weight: 500;
  font-style: italic;
}
@mixin roboto-italic-bold {
  @include family(Roboto);
  font-weight: 700;
  font-style: italic;
}
@mixin roboto-italic-black {
  @include family(Roboto);
  font-weight: 900;
  font-style: italic;
}

/// ------------------------------------ ROBOTO SLAB
@mixin roboto-slab-thin {
  @include family(RobotoSlab);
  font-weight: 100;
}

@mixin roboto-slab-light {
  @include family(RobotoSlab);
  font-weight: 300;
}
@mixin roboto-slab {
  @include family(RobotoSlab);
  font-weight: 400;
}

@mixin roboto-slab-bold {
  @include family(RobotoSlab);
  font-weight: 700;
}
